import DailyJuiceStory from "@/base/project/daily-juice-story.js";

import text from "@/base/text/index.js";

import actionsStudentAchievements from "./student-achievements.js";


const loadStoryById = (services, params = {}) => {
    const { api, actions } = services;
    const { storyId } = params;

    return async (dispatch, getState) => {
        const state = getState();

        const res = await api.juiceStories.getStoryByStudent({
            session: state.user.session,
            storyId,
        });

        let story = null;
        let error = "";

        if (res.ok) {
            story = res.story || null;
            dispatch(actions.juiceStories.setStory({ story }));
        } else {
            error = res.error || text.error;
        }

        return {
            story,
            error,
        };
    };
};

const setStoryOpened = (services, params = {}) => {
    const { api, actions } = services;
    const { juiceId, story } = params;

    const storyId = story?.ID || null;

    return async (dispatch, getState) => {
        if (!storyId || story?.isUserOpenedStory) {
            return;
        }

        const state = getState();

        const res = await api.student.setStoryOpened({
            session: state.user.session,
            juiceId,
            storyId,
        });

        let error = "";

        if (res.ok) {
            dispatch(actions.juiceStories.setStoryInfoStoryOpened({
                juiceId,
                storyId,
            }));

            if (!DailyJuiceStory.isStoryHasVideo(story)
                && !DailyJuiceStory.isStoryHasPoll(story)) {
                dispatch(actionsStudentAchievements.addStoriesRead(services));
            }
        } else {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};

const setVideoViewed = (services, params) => {
    const { api, actions } = services;
    const { juiceId, storyId, videoId } = params;

    return async (dispatch, getState) => {
        if (!videoId) {
            return;
        }

        dispatch(actions.juiceStories.setStoryInfoStoryVideoViewed({
            juiceId,
            storyId,
        }));

        const state = getState();

        const res = await api.student.setVideoViewed({
            session: state.user.session,
            juiceId,
            videoId,
        });

        let error = "";

        if (!res.ok) {
            error = res.error || text.error;
        }

        return {
            error,
        };
    };
};


const loadStoryByGrade = (services, params) => {
    const { api, actions } = services;
    const { storyId, grade } = params;

    return async (dispatch, getState) => {
        const state = getState();

        dispatch(actions.juiceStories.setStoryByGradeLoading({
            storyId,
            grade,
        }));

        const res = await api.juiceStories.getStoryByGrade({
            session: state.user.session,
            storyId,
            grade,
        });

        let error = "";
        let data = null;

        if (res.ok) {
            data = res.story || null;
        } else {
            error = res.error || text.error;
        }

        dispatch(actions.juiceStories.setStoryByGrade({
            storyId,
            grade,
            data,
            error,
        }));
    };
};


export default {
    loadStoryById,
    setStoryOpened,
    setVideoViewed,
    loadStoryByGrade,
};
